import React from 'react';
import {Result, Spin} from "antd";
import {CommonLayout} from "../commons/CommonLayout";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {PublicEventDTO} from "./types";
import './home.css'
import {Heading} from "../commons/translations/Heading";
import {EventCard} from "./EventCard";

export const Home = () => {

    const {isLoading, isError, data: upcoming} = useQuery(`upcoming-events`,
        () => axios.get<any,AxiosResponse<PublicEventDTO[]>>(`/api/events` ).then(r => r.data));

    if (isError) {
        return (<Result status={"error"} />);
    }

    return (
        <CommonLayout>
            <Heading level={5} trl={'home.greeting'}/>
            {isLoading && <Result status={"info"}><Spin /></Result>}
            {!!upcoming &&
                upcoming.map((event: PublicEventDTO) => <EventCard event={event} />)
            }
        </CommonLayout>
    )
};