import React, {useMemo} from 'react'
import {Typography} from "antd";
import {TextProps} from "antd/es/typography/Text";
import {useTranslation} from "react-i18next";

export type LabelProps = TextProps & {
    trl: string
};

export const Label: React.FC<LabelProps> = (props) => {
    const {trl} = props;
    const {t} = useTranslation();
    const text = useMemo(() => t(trl), [t]);

    return (
        <Typography.Text {...props} >{text}</Typography.Text>
    )
}