import React, {useCallback, useMemo, useState} from 'react';
import {CommonLayout} from "../commons/CommonLayout";
import {Button, Divider, Flex, Form, Input, message, Modal, Result, Select, Slider, Spin} from "antd";
import {defaultFormItemLayout} from "../commons/forms";
import {useForm} from "antd/lib/form/Form";
import {VolunteerRequest} from "./types";
import {useTranslation} from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Heading} from "../commons/translations/Heading";
import {Label} from "../commons/translations/Label";
import axios from "axios";

export const VolunteerForm: React.FC = () => {
    const {t} = useTranslation();
    const [form] = useForm<VolunteerRequest>();
    const [sent, setSent] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const systems = useMemo(() => [
        {value: 'dnd.35', label: "DnD 3.5"},
        {value: 'dnd.5e', label: "DnD 5e"},
        {value: 'dnd.5e.2024', label: "DnD 5e (2024 updated rules)"},
        {value: 'pathfinder.1', label: "Pathfinder"},
        {value: 'pathfinder.2', label: "Pathfinder 2nd edition"},
        {value: 'vtm.v20', label: "Vampire the Masquerade v20"},
        {value: 'vtm.v5', label: "Vampire the Masquerade v5"},
        {value: 'vtm.da', label: "Vampire the Masquerade Dark Ages"},
        {value: 'coc.rpg', label: "Call of Cthulhu RPG"},
        {value: 'dune.rpg', label: "Dune: Adventures in the Imperium"},
        {value: 'lodge.around', label: "Rpg Lodge: Around"},
    ], [t]);

    const frequencies = useMemo(() => [
        {value: 'week', label: t('common.week')},
        {value: 'month', label: t('common.month')},
        {value: 'trimester', label: t('common.trimester')},
    ], [t]);

    const sendVolunteeringForm = useCallback((data: VolunteerRequest) => {
        if (working || !executeRecaptcha) {
            return;
        }
        setWorking(true);
        executeRecaptcha('volunteer')
        .then(captcha => {
            return axios.post('api/volunteer', {
                ...data,
                captcha,
            });
        })
        .then(() => setSent(true))
        .catch(() => {
            message.error(t('volunteer.page.failure'))
        })
        .finally(() => setWorking(false));
    }, [executeRecaptcha,t, setWorking, working]);

    return (
        <CommonLayout>
            {!sent &&
                <>
                    <Modal open={working} closable={false} footer={<></>} >
                        <Result status={"info"}>
                            <Spin />
                            <Label trl={'common.loading'} />
                        </Result>
                    </Modal>
                    <Flex justify={"center"}>
                        <Heading trl={'volunteer.page.title'} level={3} />
                    </Flex>
                    <Label trl={'volunteer.page.pitch'} italic />
                    <Divider />
                    <Form {...defaultFormItemLayout}
                          form={form}
                          onFinish={sendVolunteeringForm}>
                        <Label trl={'volunteer.page.skills'} italic />
                        <Form.Item rules={[{required: true}]} label={t('volunteer.form.fields.systems')} name={"systems"}>
                            <Select options={systems} mode={"multiple"} />
                        </Form.Item>
                        <Divider />
                        <Label trl={'volunteer.page.availability'} italic />
                        <Form.Item rules={[{required: true}]} initialValue={2} label={t('volunteer.form.fields.availableHours')} name={"availableHours"}>
                            <Slider min={1} max={5} tooltip={{ open: true }} />
                        </Form.Item>
                        <Form.Item rules={[{required: true}]} label={t('volunteer.form.fields.availabilityFrequency')} name={"frequency"}>
                            <Select options={frequencies} />
                        </Form.Item>
                        <Divider />
                        <Label italic trl={'volunteer.page.contact'} />
                        <Form.Item rules={[{required: true}]} label={t('volunteer.form.fields.name')} name={"name"}>
                            <Input />
                        </Form.Item>
                        <Form.Item rules={[{required: true}]} label={t('volunteer.form.fields.contactInfo')} name={"contactInfo"}>
                            <TextArea rows={3} />
                        </Form.Item>
                        <Button type={"primary"} style={{width: '100%'}} onClick={form.submit}>{t('volunteer.page.action')}</Button>
                    </Form>
                </>
            }
            {sent &&
                <Result status={"success"} title={t('common.success')} subTitle={t('volunteer.page.success')} />
            }
        </CommonLayout>
    )
}