import React, {PropsWithChildren, useContext, useEffect} from 'react';
import enUS from 'antd/locale/en_US';
import elGR from 'antd/locale/el_GR';
import {App, ConfigProvider, ConfigProviderProps} from "antd";
import dayjs from "dayjs";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslations from "./translations-en.json";
import greekTranslations from "./translations-el.json";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

export type LocaleType = ConfigProviderProps["locale"];
export type LocaleChoice = {
    key: string;
    value: LocaleType;
    url: string | undefined;
}

export type LocaleContextType = {
    locale: LocaleType;
    options: LocaleChoice[]
    setLocale: (locale: LocaleType) => void;
}

const defaultValue: Pick<LocaleContextType, 'locale' | 'options'> = {
    locale: elGR,
    options: [{
        key: 'lang.greek',
        value: elGR,
        url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/1920px-Flag_of_Greece.svg.png'
    }, {
        key: 'lang.english',
        value: enUS,
        url: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_the_United_Kingdom_%281-1%29.png'
    }]
}

const LocaleContext = React.createContext<LocaleContextType | null>(null);

export const LocaleContextProvider: React.FC<PropsWithChildren> = (props) => {
    const [locale, setLocale] = React.useState<LocaleType>(defaultValue.locale);
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                en: {
                    translation: englishTranslations
                },
                el: {
                    translation: greekTranslations
                }
            },
            lng: "el", // if you're using a language detector, do not define the lng option
            fallbackLng: "el",
            interpolation: {
                escapeValue: false
            }
        });

    useEffect(() => {
        if (!!locale && locale?.locale !== i18n.language) {
            i18n.changeLanguage(locale.locale)
            dayjs.locale(locale?.locale);
        }
    }, [locale]);

    return (
        <LocaleContext.Provider value={{
            locale,
            setLocale,
            options: defaultValue.options
        }}>
            <ConfigProvider locale={locale} theme={{
                token: {
                    fontFamily: "Gill Sans, sans-serif"
                }
            }}>
                <App>
                    <GoogleReCaptchaProvider reCaptchaKey={"6LcN2i0qAAAAAJST7lLm5aMMGZZpKUGxTwLYHDsj"} language={locale?.locale}>
                        {props.children}
                    </GoogleReCaptchaProvider>
                </App>
            </ConfigProvider>
        </LocaleContext.Provider>
    )
}

export const useLocaleContext = () => {
    const context = useContext(LocaleContext);
    if (!context) {
        throw new Error("useLocaleContext must be used within LocaleContextProvider");
    }

    return context;
}

