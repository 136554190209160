import React, {useCallback} from 'react';
import {Button, Card, Form, Layout, message} from "antd";
import Password from "antd/es/input/Password";
import {useForm} from "antd/lib/form/Form";
import axios from "axios";
import {useNavigate} from "react-router";
import {startAuth} from './auth';
import {useTranslation} from "react-i18next";
import {Label} from "../commons/translations/Label";

const LoginPage = () => {
    const [form] = useForm<{password: string|undefined}>();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const doLogin = useCallback(() => {
        const token = btoa(`admin:${form.getFieldsValue().password}`);
        axios.get(`/api/admin/login`, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
        .then(() => {
            startAuth(token);
            message.success(t('auth.success')).then();
            navigate('/admin');
        })
        .catch(() => {
            message.error(t('auth.failure')).then();
        })

    }, [form, navigate]);

    return (
        <>
            <Layout style={{height:'100vh'}}>
                <div style={{
                    margin: 'auto',
                    width: '50%'
                }}>
                    <Card>
                        <Label trl={'login.prompt'} />
                        <Form form={form} onFinish={doLogin} >
                            <Form.Item name="password">
                                <Password />
                            </Form.Item>
                            <div style={{width: '100%'}}>
                                <Button type={"default"} style={{float: 'right'}} onClick={form.submit}>
                                    <Label trl={'login.button.text'} />
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default LoginPage;