import React from 'react';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";

export type DateBoxProps = {
    date: number;
}

export const DateBox: React.FC<DateBoxProps> = (props) => {
    const {date} = props;
    const dayInMonth = dayjs(date).date();
    const month = dayjs(date).month().toString();
    const monthLabels = new Map(Object.entries({
        '0' : 'JAN',
        '1' : 'FEB',
        '2' : 'MAR',
        '3' : 'APR',
        '4' : 'MAY',
        '5' : 'JUN',
        '6' : 'JUL',
        '7' : 'AUG',
        '8' : 'SEP',
        '9' : 'OCT',
        '10': 'NOV',
        '11': 'DEC'
    }));
    const {t} = useTranslation();

    return (
        <div style={{
            width: '85px',
            height: '85px',
            backgroundColor: '#555',
            display: 'inline-block',
            textAlign: 'center',
            color: 'white'
        }}>
            <code style={{fontSize: '30px'}}>{dayInMonth}</code>
            <br/>
            <code>{t(monthLabels.get(month)!)}</code>
        </div>
    )
}