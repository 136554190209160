import React from 'react'
import {Typography} from "antd";
import {TitleProps} from "antd/lib/typography/Title";
import {useTranslation} from "react-i18next";

export type HeadingProps = TitleProps & {
    trl: string
};

export const Heading: React.FC<HeadingProps> = (props) => {
    const {trl} = props;
    const {t} = useTranslation();
    return (
        <Typography.Title {...props} >{t(trl)}</Typography.Title>
    )
}