import React from 'react';
import {useParams} from "react-router";
import {CommonLayout} from "../commons/CommonLayout";
import {Descriptions, Flex, Result, Spin, Typography} from "antd";
import {CalendarOutlined, InfoCircleOutlined, PushpinOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {PublicEventDTO} from "../home/types";
import {TableCard} from "./TableCard";
import {Label} from "../commons/translations/Label";

export const Reservation: React.FC = () => {
    const { event } = useParams();
    const {data: target, isLoading, isError} = useQuery(`public-event-id-${event}`,
        () => axios.get<any,AxiosResponse<PublicEventDTO>>(`/api/events/${event}` ).then(r => r.data),
        {
           enabled: !!event
        });

    return (
        <CommonLayout>
            {isLoading && !isError &&
                <Result status={"info"}>
                    <Spin />
                    <Label trl={'common.loading'} />
                </Result>
            }
            {isError &&
                <Result status={"error"}>
                    <Label trl={'common.failure'} />
                </Result>
            }
            {!!target &&
                <>
                    <Flex justify={"center"}>
                        <Typography.Title level={2}>{target.title}</Typography.Title>
                    </Flex>
                    <Descriptions>
                        <Descriptions.Item label={<CalendarOutlined />}>
                            <Typography.Text italic>{dayjs(target.startsAt).format('DD/MM/YYYY HH:mm')}</Typography.Text>
                        </Descriptions.Item>
                        <Descriptions.Item label={<PushpinOutlined />}>
                            {target.navigationLink
                                ? (<Typography.Link href={target.navigationLink} >{target.location}</Typography.Link>)
                                : (<Typography.Text>{target.location}</Typography.Text>)
                            }
                        </Descriptions.Item>
                        {!!target.description &&
                            <Descriptions.Item label={<InfoCircleOutlined />}>
                                <Typography.Paragraph>
                                    {target?.description}
                                </Typography.Paragraph>
                            </Descriptions.Item>
                        }
                    </Descriptions>
                    {
                        target.tables
                            .sort(table => table.position)
                            .map(table =>
                                <TableCard table={table} key={table.id}/>
                            )
                    }
                </>
            }
        </CommonLayout>
    );
}