import React, {useCallback} from 'react';
import {PublicEventDTO} from "./types";
import {DateBox} from "./DateBox";
import {Card, Image, Typography} from "antd";
import {Label} from "../commons/translations/Label";
import {useNavigate} from "react-router";

export const EventCard: React.FC<{event: PublicEventDTO}> = (props) => {
    const {event} = props;
    const navigate = useNavigate();
    const selectEvent = useCallback((event: PublicEventDTO) => {
        navigate(`/events/${event.id}`);
    }, [navigate]);

    return (
        <Card key={event.id}  onClick={() => selectEvent(event)} className="card-public-event">
            <div style={{display: 'flex'}}>
                <DateBox date={event.startsAt} />
                <div style={{height: '85px', flexGrow: '1', marginLeft: '20px'}}>
                    <Typography.Text strong >{event.title}</Typography.Text>
                    <br/>
                    <Typography.Text>{event.location}</Typography.Text>
                    <br/>
                    <Typography.Text italic>{event.tables.length + ' '}</Typography.Text>
                    <Label trl={event.tables.length === 1 ? 'home.table.singular' : 'home.table.plural'} />
                    <Typography.Text italic>!</Typography.Text>
                </div>
            </div>
            {!!event.mediaUrl && <><br/><Image preview={false} src={event.mediaUrl} style={{height: '20em'}} alt={'event media'}/></>}
        </Card>
    )
}